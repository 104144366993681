import 'slick-carousel';

import klugeCSP from '/lib/csp-kluge'
import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initA11yFunctions from '/lib/accessibility'
import initFooterMobileMenu from '/lib/footer'
import initMobileSliders from '/lib/mobileSliders'
import initMeganav from '/lib/meganav'
import initChatBot from './lib/chatbot';
import initAlertBanner from './lib/alert-banner';
import initPopupNotice from './lib/popupNotice';

initGreenSock()
initHeadroom()
initMobileNav()
initFooterMobileMenu()
initMobileSliders()
initMeganav()
initChatBot()
initAlertBanner()
initPopupNotice()
initA11yFunctions()
klugeCSP()
