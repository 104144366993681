import $ from 'jquery'

export default function initChatBot(){
  var agentName = "";
  function autoClose(data, eventInfo) {
    if (window.console && window.JSON) {
      if (data.agentName) {
        agentName = data.agentName;
      }
      if (
        data.state == "ended" &&
        (agentName.includes("Survey") || agentName.includes("Virtual Assistant"))
      ) {
        document.getElementsByClassName("lp_close lpc_maximized-header__close-button")[0].click();
      }
    }
  }
  lpTag.events.bind("lpUnifiedWindow", "conversationInfo", autoClose);
  lpTag.events.bind("lpUnifiedWindow", "state", autoClose);
}
