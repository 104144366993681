import $ from 'jquery'

var featuredPostsSlider = $('.js-featured-posts');
var featuredPostsSettings = {
  arrows: true,
  dots: false,
  nextArrow: '<button type="button" class="slick-next"><svg width="29" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 52L26 27L2 2" stroke="#425050" stroke-width="4"/></svg></button>',
  prevArrow: '<button type="button" class="slick-prev"><svg width="29" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 2L3 27L27 52" stroke="#425050" stroke-width="4"/></svg></button>',
};

var iconColumnsSlider = $('.js-icon-row');
var iconColumnsSettings = {
  arrows: false,
  dots: false,
  infinite: false
};

var speechBubbleSlider = $(".js-speech-bubble");
var speechBubbleSliderSettings = {
  arrows: false,
  dots: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

initResizeFunction(featuredPostsSlider, 960, featuredPostsSettings);
initResizeFunction(iconColumnsSlider, 700, iconColumnsSettings);
initResizeFunction(speechBubbleSlider, 960, speechBubbleSliderSettings);

function initSliders(){
  if($(window).width() <= 960){
    if ($('.js-featured-posts').length) {
      console.log('Init Featured Posts slider');
      featuredPostsSlider.slick(featuredPostsSettings);
    }
  }
  if($(window).width() <= 960){
    if ($('.js-speech-bubble').length) {
      console.log('Init Speach Bubble slider');
      speechBubbleSlider.slick(speechBubbleSliderSettings);
    }
  }
  if($(window).width() <= 700){
    if ($('.js-icon-row').length) {
      console.log('Init Icon Column slider');
      iconColumnsSlider.slick(iconColumnsSettings);
    }
  }
}

// All sliders should have slick/unslick on window resize
function initResizeFunction(slider, breakpoint, settings){
  $(window).on('resize', function() {
    if ($(window).width() <= breakpoint) {
      if (!slider.hasClass('slick-initialized'))
      return slider.slick(settings);
    }
    else{
      if (slider.hasClass('slick-initialized'))
      slider.slick('unslick');
     return
    }
  });  
}

export default function initMobileSliders(){
  $(document).ready( initSliders )
}


