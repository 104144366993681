import $ from 'jquery'

const $doc = $(document)
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')

function showFooterMobileMenu(event) {
  event.preventDefault()
  var target = event.target;

  $(target).toggleClass('dropdown-toggle--rotate')
  var parent = $(target).parent();
  var menu = $(parent).find('.sub-menu');

  $(menu).toggle();
}

export default function initFooterMobileMenu(){
  $doc.on('click', '.footer__links .dropdown-toggle', showFooterMobileMenu )
}
