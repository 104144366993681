import $ from 'jquery'
const $doc = $(document)

function checkForExternalLink(event) {
    var currentUrl = window.location.host;
    if(!$(this).attr('data-lang')){
        var linkHref = $(this).attr('href');
        if(
            linkHref.indexOf("instagram.com") != -1
            || linkHref.indexOf("apps.apple.com") != -1
            || linkHref.indexOf("play.google.com") != -1
            || linkHref.indexOf("nutmegstatefcu.banzai.org") != -1
          ){
            if (linkHref.indexOf(currentUrl) === -1) {
                // it's external link..
                event.preventDefault();
                openPopup(linkHref);
            }
        }
        else{
            if ($(this).attr('target') === '_blank'
                && linkHref.indexOf("ebanking") === -1
                && linkHref.indexOf("newmember") === -1
                && linkHref.indexOf("nutmegstatefcu") === -1
                && linkHref.indexOf("javascript:void(0)") === -1
                && linkHref.indexOf("onboard.cotribute.co/flows/e0c474dd-ef6d-46de-8a8c-ffe030ad369e") === -1
                && linkHref.indexOf("onboard.cotribute.co/flows/99e0e419-db7c-45f9-9bbf-b7f2a3b182ad") === -1
                && linkHref.indexOf("onboard.cotribute.co/flows/38a568d5-65ba-4916-bb1a-5acc0e26fb94") === -1
                && linkHref.indexOf("#") === -1) {
                console.log('should open popup');
                if (linkHref.indexOf(currentUrl) === -1) {
                    // it's external link..
                    event.preventDefault();
                    openPopup(linkHref);
                }
            }
        }
    }
    else{
        console.log('Translate!');
    }
}

function openPopup(linkHref){
    $(".popup-notice").addClass('active');
    $(".popup-notice__ok").attr('href', linkHref);
}

function closePopupNotice(event) {
    $(".popup-notice").removeClass('active');
}

function setTargetBlank(){
    $("a[href^=http]").each(function(){
        if(this.href.indexOf(location.hostname) === -1 && this.href.indexOf("ebanking") === -1 && this.href.indexOf("newmember") === -1 ) {
            $(this).attr({
                target: "_blank",
            });
        } 
    });
}

export default function initPopupNotice() {
    $doc.on('click', 'a:not(".popup-notice__close"):not(".popup-notice__ok")', checkForExternalLink)
    $doc.on('click', '.popup-notice__close', closePopupNotice)
    $doc.on('click', '.popup-notice__ok', closePopupNotice)
    $(document).ready( setTargetBlank )
}
