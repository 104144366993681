import $ from 'jquery'

export default function initMeganav(){
    $('.megamenu__left-title').each(function (index) {
        let title = $(this).parent().parent().siblings().text();
        $(this).text(title);
    });
    $('.megamenu__left-bubble').each(function (index) {
        let text = $(this).parent().parent().siblings().data('desc');
        $(this).text(text);
    });
    let url = window.location.origin;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let search = ''
    if ( urlParams.get('s') ) {
        search = urlParams.get('s');
    }
    $('.search.menu-item .megamenu').append('<div class="megamenu__search"><p>What can we help you find?</p><form role="search" method="get" class="search-form" action="' + url +'"><label><span class="screen-reader-text">Search for:</span><input type="search" class="search-field" placeholder="Search" value="'+search+'" name="s"></label><input type="submit" class="search-submit" value="Go"></form></div>');
    $('.search.menu-item .megamenu__submenu, .search.menu-item .megamenu__search').wrapAll('<div class="megamenu__search-right" />');
    $('.search.menu-item > span').attr('tabindex', '0');
    $(window).on("load resize", function (e) {
        let navwidth = $('.nav--main').width();
        let meganavwidth = navwidth - 20;
        if ($(window).width() > 960 && $(window).width() < 1199) {
            meganavwidth = navwidth - 40;
        }
        if ($(window).width() > 1199 && $(window).width() < 1439) {
            meganavwidth = navwidth - 60;
        }
        $('.megamenu__submenu').css('width', meganavwidth);
        $('.megamenu__search-right').css('width', meganavwidth);
    });

    //Translation plugin tweak
    $('#flags ul li a[title=English]').addClass('is-hidden');
    $(document).on('click', '#flags ul li a', function() {
        $('#flags ul li a').removeClass('is-hidden');
        $(this).addClass('is-hidden');
    });
}
