import $ from 'jquery'
const $doc = $(document)
import { getCookie, setCookie } from '/lib/cookie'

function closeAlert(event) {
    const $banner = $('.header__alert')
    $banner.slideUp()
    setCookie('mg-alert-banner', 'true', 1);
}

export default function initAlertBanner() {
    if (!getCookie('mg-alert-banner')) {
        const $banner = $('.header__alert')
        $banner.slideDown()
    }
    $doc.on('click', '.js-close-alert', closeAlert)
}